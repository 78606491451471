import React from 'react'
import Layout from '../Layout.js'
import SplashHeader from '../SplashHeader.js'

export default () => {
  return (
    <Layout>
      <SplashHeader height="30vh"
        image="https://images.unsplash.com/photo-1521791055366-0d553872125f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80">
        <div className="splash-text">
          <h1 className="display-5 mb-4">
            <strong>Let's talk business</strong>
          </h1>
        </div>
      </SplashHeader>
      <div className="container-fluid">
        <form className="contact-form pt-5"
          onSubmit={event => open_email(event)}>
          <Row>
            <label>Name</label>
            <Input name="name" placeholder="e.g. John Smith"/>
          </Row>
          <div className="row">
            <div className="col-md mb-3">
              <label>Email</label>
              <Input name="email" placeholder="e.g. john.smith@company.com"/>
            </div>
            <div className="col-md mb-3">
              <label>Phone</label>
              <Input name="phone" placeholder="e.g. 111 222 3333"/>
            </div>
          </div>
          <Row>
            <label>Message</label>
            <Input type="textarea" name="message" placeholder="e.g. John Smith"/>
          </Row>
          <div className="d-flex">
            <button className="btn btn-lg btn-brand ml-auto">
              Send
            </button>
          </div>
        </form>
      </div>
    </Layout>
  )
}

function Row({children}) {
  return <div className="mb-3">{children}</div>
}

function Input(props) {
  if (props.type==='textarea') {
    return <textarea className="form-control" {...props}/>
  }
  return (
    <input className="form-control" {...props} />
  )
}

function open_email(event) {
  event.preventDefault()
  let form = event.target
  let salutation = [form.name, form.phone, form.email]
  .map(e=>e.value)
  .filter(Boolean)
  .join(' | ')
  let subject = encodeURIComponent('Project inquiry')
  let body = encodeURIComponent(`Hi,\n\n${form.message.value}\n\n${salutation}`)
  let mailto = `mailto:hire@floatingisland.co?subject=${subject}&body=${body}`
  window.location.href = mailto
}
